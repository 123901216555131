@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/typography' as typo;
@use '~/src/sass/abstracts/variables/structure';

.lvp-cta {
	background-color: colors.$grey-100;
	border-radius: 20px;
}

.lvp-cta__wrapper {
	display: grid;
	grid-gap: 3rem;
	align-items: center;
	padding: 3rem;
	font-family: typo.$secondary;
}

.lvp-cta .lvp-cta__title {
	margin-bottom: 1rem;
	font-size: 3.4rem;
	font-weight: 400;
	color: colors.$grey-400;
}

.lvp-cta__text {
	@extend %font-md;
}

.lvp-cta__link {
	display: flex;
	align-items: center;
	min-width: max-content;
	overflow-x: hidden;
	text-decoration: none;

	.link__icon-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		height: 4rem;
		color: colors.$grey-400;
		border: 2px solid colors.$grey-200;
		border-radius: 50%;
		transition: border-color 100ms 0ms ease;

		> * {
			flex-grow: 0;
			flex-shrink: 0;
		}

		&::after {
			position: absolute;
			inset: -2px;
			content: '';
			background-color: colors.$grey-400;
			border-radius: 50%;
			transition: transform 300ms ease;
			transform: scale(0);
		}

		i {
			position: relative;
			z-index: 1;
			color: colors.$grey-400;

			@extend %font-sm;

			transition: color 200ms ease;
		}
	}

	.link__text {
		padding-left: 2rem;
		font-weight: 700;
		color: colors.$grey-400;

		@extend %font-sm;

		text-transform: uppercase;
		opacity: 0;
		transition: opacity 200ms ease, transform 200ms ease;
		transform: translateX(-60%);
	}

	&:focus,
	&:active,
	&:hover {
		outline: none;

		.link__icon-wrapper {
			border-color: colors.$grey-400;
			transition: border-color 150ms 150ms ease;
		}

		.link__icon-wrapper::after {
			transform: scale(1);
		}

		i {
			color: colors.$white;
		}

		.link__text {
			opacity: 1;
			transform: translateX(0%);
		}
	}
}

@media screen and (min-width: structure.$tablet-portrait) {
	.lvp-cta__wrapper {
		grid-template-columns: repeat(2, minmax(1rem, 1fr));
	}
}
