/**
 * Breakpoints
 */
$tablet-portrait: 600px;
$tablet-landscape: 900px;
$desktop: 1200px;
$big-desktop: 1800px;

/**
 * Layout sizes
 */
$size-site-small: 92rem;
$size-site: 146rem;
$header-height: 8rem;
$size-sidebar: 26.8rem;
