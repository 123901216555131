$white: #ffffff;
$black: #000000;
$grey-100: #f0f0f0;
$grey-200: #bababa;
$grey-300: #7e7e7e;
$grey-400: #2f2f2f;
$red: #ff5c48;
$orange: #f7a454;
$green: #75b760;
$blue: #3464be;

$text-base: $grey-400;

$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: #4169e1; //royalblue
$color__link-visited: #800080; //purple
$color__link-hover: #191970; //midnightblue
$color__text-main: #404040;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;
